<template>
<v-container style="max-width: 1400px; margin: 0 auto;">
    <v-row>
        <v-col>
            <h3>Question Set Editor</h3>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <Editor/>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import Editor from '@/components/Questions/Editor/Editor'
export default {
    components: {
        Editor
    }
}
</script>

<style>

</style>